/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  Container,
  ModulesResponse,
  PlayBookResponse,
  Scenario
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class PlayBookService {
  constructor(
    private http: HttpClient,
  ) {} getApiPlaybookData<TData = PlayBookResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/playbook/data`,options
    );
  }
 getApiPlaybookModules<TData = ModulesResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/playbook/modules`,options
    );
  }
 postApiPlaybookScenario<TData = unknown[]>(
    scenario: Scenario, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/playbook/scenario`,
      scenario,options
    );
  }
 postApiPlaybookGetCosmos<TData = unknown[]>(
    container: Container, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/playbook/get_cosmos`,
      container,options
    );
  }
};

export type GetApiPlaybookDataClientResult = NonNullable<PlayBookResponse>
export type GetApiPlaybookModulesClientResult = NonNullable<ModulesResponse>
export type PostApiPlaybookScenarioClientResult = NonNullable<unknown[]>
export type PostApiPlaybookGetCosmosClientResult = NonNullable<unknown[]>
