
import { BacktestService } from "./orval/interfaces/backtest";
import { DataBricksService } from "./orval/interfaces/data-bricks";
import { GeneralService } from "./orval/interfaces/general";
import { OptimizerService } from "./orval/interfaces/optimizer";
import { ProxyService } from "./orval/interfaces/proxy";
import { TenaskaService } from "./orval/interfaces/tenaska";
import { TransactionsService } from "./orval/interfaces/transactions";
import { AncillaryIntervalData, AncillaryResourceSubmission, AncillaryResourceSubmissionOverride, AncillarySubmissionDocument, 
    AppSettings, CopIntervalData, CopResourceSubmission, CopResourceSubmissionOverride, CopSubmissionDocument, 
    EnergySubmissionDocument, IntervalHealth, RealTimeIntervalData, RealTimeResourceSubmission, RealTimeResourceSubmissionOverride } from "./orval/schemas";

export const providers = [
    GeneralService,
    BacktestService,
    OptimizerService,
    ProxyService,
    TenaskaService,
    DataBricksService,
    TransactionsService
];

// Derived convenience types
export type ServiceType = keyof Omit<AppSettings, 'id'>;

export { ObjectBaseResourceSubmissionObjectIOptimizedSubmissionDocument as IOptimizedSubmissionDocument } from './orval/schemas/objectBaseResourceSubmissionObjectIOptimizedSubmissionDocument';

export type AnySubmissionDocument = AncillarySubmissionDocument | CopSubmissionDocument | EnergySubmissionDocument;

export type AnyResourceSubmission = (AncillaryResourceSubmission | CopResourceSubmission | RealTimeResourceSubmission);

/**
 * Should really be used in place of AnyResourceSubmission[] because the collection will always be homogenous
 */
export type AnyResourceCollection = AncillaryResourceSubmission[] | CopResourceSubmission[] | RealTimeResourceSubmission[];

export type AnyOverride = AncillaryResourceSubmissionOverride | RealTimeResourceSubmissionOverride | CopResourceSubmissionOverride;

export type BatteryHealthFields = keyof Omit<IntervalHealth, 'dateTimeUtc' | 'hourEnding'>;

export type AnyIntervalData = AncillaryIntervalData | CopIntervalData | RealTimeIntervalData;

export type AnyIntervalCollection = AncillaryIntervalData[] | CopIntervalData[] | RealTimeIntervalData[];