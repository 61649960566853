/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */

export type SubmissionType = typeof SubmissionType[keyof typeof SubmissionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubmissionType = {
  ancillaries: 'ancillaries',
  realtime: 'realtime',
  cop: 'cop',
} as const;
