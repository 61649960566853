/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  IpAddress,
  IpList,
  PostApiDatabricksUsersDeleteParams
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class DataBricksService {
  constructor(
    private http: HttpClient,
  ) {} getApiDatabricksUsersGet<TData = IpList>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/databricks/users/get`,options
    );
  }
 postApiDatabricksUsersChange<TData = IpList>(
    ipAddress: IpAddress, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/databricks/users/change`,
      ipAddress,options
    );
  }
 postApiDatabricksUsersDelete<TData = IpList>(
    params?: PostApiDatabricksUsersDeleteParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/databricks/users/delete`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
};

export type GetApiDatabricksUsersGetClientResult = NonNullable<IpList>
export type PostApiDatabricksUsersChangeClientResult = NonNullable<IpList>
export type PostApiDatabricksUsersDeleteClientResult = NonNullable<IpList>
