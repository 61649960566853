/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  ArchiveWithAncillary,
  GetApiForecastAncillariesParams,
  GetApiForecastSolarBandsParams,
  SolarBand
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class ForecastService {
  constructor(
    private http: HttpClient,
  ) {} getApiForecastAncillaries<TData = ArchiveWithAncillary[]>(
    params?: GetApiForecastAncillariesParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/forecast/ancillaries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiForecastSolarBands<TData = SolarBand[]>(
    params?: GetApiForecastSolarBandsParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/forecast/SolarBands`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
};

export type GetApiForecastAncillariesClientResult = NonNullable<ArchiveWithAncillary[]>
export type GetApiForecastSolarBandsClientResult = NonNullable<SolarBand[]>
