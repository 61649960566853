/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  GetApiProxyIgnitionBatteryHealthHiResParams,
  GetApiProxyIgnitionBatteryHealthParams,
  IntervalHealthAssetIntervalData
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class ProxyService {
  constructor(
    private http: HttpClient,
  ) {} getApiProxyVersion<TData = string>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/Proxy/version`,options
    );
  }
 getApiProxyYesPath<TData = void>(
    path: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/Proxy/yes/${path}`,options
    );
  }
 getApiProxyIgnitionPath<TData = void>(
    path: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/Proxy/ignition/${path}`,options
    );
  }
/**
 * @summary Get all required Ignition fields for both Noble BESS units for the specified day.
Automatically prepends previous day's latest readings to the start of this dataset (if no data for field).
 */
 getApiProxyIgnitionBatteryHealth<TData = IntervalHealthAssetIntervalData[]>(
    params?: GetApiProxyIgnitionBatteryHealthParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/Proxy/ignition/battery_health`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiProxyIgnitionBatteryHealthHiRes<TData = IntervalHealthAssetIntervalData>(
    params?: GetApiProxyIgnitionBatteryHealthHiResParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/Proxy/ignition/battery_health/hi_res`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
};

export type GetApiProxyVersionClientResult = NonNullable<string>
export type GetApiProxyYesPathClientResult = NonNullable<void>
export type GetApiProxyIgnitionPathClientResult = NonNullable<void>
export type GetApiProxyIgnitionBatteryHealthClientResult = NonNullable<IntervalHealthAssetIntervalData[]>
export type GetApiProxyIgnitionBatteryHealthHiResClientResult = NonNullable<IntervalHealthAssetIntervalData>
