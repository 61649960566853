/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */

export type OperatingMode = typeof OperatingMode[keyof typeof OperatingMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatingMode = {
  on: 'on',
  onreg: 'onreg',
  ontest: 'ontest',
  out: 'out',
  onclr: 'onclr',
  onrgl: 'onrgl',
  outl: 'outl',
  onruc: 'onruc',
  onos: 'onos',
  onosreg: 'onosreg',
  ondsrreg: 'ondsrreg',
  ondsr: 'ondsr',
  off: 'off',
  offns: 'offns',
  onemr: 'onemr',
  onrr: 'onrr',
  emr: 'emr',
  onrl: 'onrl',
  onoptout: 'onoptout',
  offqs: 'offqs',
  emrswgr: 'emrswgr',
} as const;
