import { FormatDate, keyBy } from "@app/utils";
import { AnyOverride, AnyResourceCollection, AnyResourceSubmission } from "@models/api/index";
import { TransactionsService } from "@models/api/orval/interfaces/transactions";
import {
  AncillaryOverride,
  CopOverride,
  RealtimeOverride,
  SubmissionType
} from "@models/api/orval/schemas";
import { SubType } from "@models/submissions";
import _ from "lodash";
import { firstValueFrom, Observable } from "rxjs";

type CachedOverrides = {
  [date: string]: {
    [SubmissionType.ancillaries]: AncillaryOverride,
    [SubmissionType.cop]: CopOverride,
    [SubmissionType.realtime]: RealtimeOverride
  }
}

export class OverrideCache {

  private _overrides: CachedOverrides = {};
  constructor(private transactions: TransactionsService) { }


  /**
   * Updates or creates an override for the date and submission type in question.
   * If overrides already exist, then overwrite just the specified elements of the resource submission array.
   * @param replace When true will replace override for this date with data (or remove override if all resource empty)
   */
  async updateOverrides(bidDate: string, submissionType: SubType, resources: AnyResourceCollection, replace : boolean = false) {
    const override = this.getLocalItem(bidDate, submissionType);

    let submitResources: Record<string, AnyResourceSubmission> = {};
    
    if (override && !replace) {
      submitResources = keyBy<AnyResourceSubmission>(override.resourceSubmission, 'resource')
    }
    for(let r of resources){
      submitResources[r.resource] = r;
    }

    let val = Object.values(submitResources);
    let hasData = val.filter(v => v.intervals.length > 0).length > 0;
    if(hasData){
      let submission: AnyOverride = {
        ...override,
        submissionType,
        bidDate: FormatDate.UITimezone(bidDate, 'YYYY-MM-DD'),
        // TODO: Remove this ignore
        // @ts-ignore
        resourceSubmission: val
      }
      return await this.saveAsOverride(submission);
    }
    // Delete override completely if there is no longer any data in it
    else if(override?.id){
      await firstValueFrom(this.transactions.deleteApiTransactionsOverrideId(override.id));
      delete this._overrides[override.bidDate]?.[submissionType];
    }
    return undefined;
  }

  /**
   * Fetch requested item from cache without going back to server
   */
  getLocalItem(date: Date | string, typeKey: SubType): AnyOverride | null {
    const dateKey = FormatDate.UITimezone(date, 'YYYY-MM-DD');
    const item = this._overrides[dateKey]?.[typeKey] ?? null;
    return item;
  }
  /**
   * Save the specified override to the database
   * @param data 
   * @returns 
   */
  private async saveAsOverride(data: AnyOverride) {
    
    let p: Observable<AnyOverride>;
    const t = this.transactions;
    switch (data.submissionType) {
      case SubmissionType.ancillaries:
        p = t.postApiTransactionsOverridesAncillaries(data as AncillaryOverride);
        break;
      case SubmissionType.cop:
        p = t.postApiTransactionsOverridesCop(data as CopOverride);
        break;
      case SubmissionType.realtime:
        p = t.postApiTransactionsOverridesRealtime(data as RealtimeOverride);
        break;
    }
    if (p) {
      let res = await firstValueFrom(p);
      _.set(this._overrides, `${res.bidDate}.${data.submissionType}`, res);
      // this.overrides.overridesLoaded.set(true);
      return res;
    }
    throw new Error(`Cannot save override for unknown type: ${data.submissionType}`);
  }
  // TODO: Convert this to using ng query
  async getAsOverrides() {
    let res: AnyOverride[] = await firstValueFrom(this.transactions.getApiTransactionsOverridesFuture<AnyOverride[]>())

    this._overrides = {};
    for (let o of res) {
      _.set(this._overrides, `${o.bidDate}.${o.submissionType}`, o);
    }
    return this._overrides;
  }
}